import { Fragment } from 'react'

import { Divider, Tooltip } from 'antd'
import DOMPurify from 'dompurify'

import * as S from './styles'

import { truncateString } from '@/common'
import { BGDataFormData, FilterIcon } from '@/features/BG'

interface BGProps {
  data: BGDataFormData
}

export function PublicationSummary({ data }: BGProps) {
  const hasFilters = data.filters && data.filters.length > 0
  const hasIcons = data.icons && data.icons.length > 0

  const SelectedFilterIcons = ({ items }: { items: FilterIcon[] }) => {
    return (
      <S.FilterIconsContainer>
        {items.map((item, index) => (
          <div key={index}>
            <S.Label>{item.name}</S.Label>

            {item?.values?.map((value, index) => {
              const isLastValue = index === item.values.length - 1

              return (
                <Fragment key={`${index}-${value}`}>
                  <S.DataText>{value}</S.DataText>
                  {!isLastValue && <Divider type="vertical" />}
                </Fragment>
              )
            })}
          </div>
        ))}
      </S.FilterIconsContainer>
    )
  }

  return (
    <S.Container>
      <S.BGLabel>Dados do Produto</S.BGLabel>

      <S.BGDataContainer>
        <S.RowContainer>
          <S.Column>
            <div>
              <S.Label>ID do Produto</S.Label>
              <S.DataText>{String(data.productId)}</S.DataText>
            </div>

            <div>
              <S.Label>TextLink</S.Label>
              <S.DataText>{data.textLink}</S.DataText>
            </div>

            <div>
              <S.Label>Marca</S.Label>
              <S.DataText>{data.brand_id}</S.DataText>
            </div>

            {data.MGZProductName && (
              <div>
                <S.Label>Nome do Produto (Magazine Luiza)</S.Label>
                <S.DataText>{truncateString(data.MGZProductName, 40)}</S.DataText>
              </div>
            )}
          </S.Column>

          <S.Column>
            <div>
              <S.Label>Nome do Produto</S.Label>
              <S.DataText>{data.productName}</S.DataText>
            </div>

            <div>
              <S.Label>Nome do SKU</S.Label>
              <Tooltip title={data?.skuName || ''}>
                <S.DataText>{truncateString(data?.skuName || '', 50)}</S.DataText>
              </Tooltip>
            </div>

            <div>
              <S.Label>Categoria</S.Label>
              <S.DataText>{data.category}</S.DataText>
            </div>

            {data.CRFProductName && (
              <div>
                <S.Label>Nome do Produto (Magazine Luiza)</S.Label>
                <S.DataText>{truncateString(data.CRFProductName, 40)}</S.DataText>
              </div>
            )}
          </S.Column>

          <S.Column>
            <div>
              <S.Label>Palavras Similares</S.Label>
              <S.DataText>{data.similarWords}</S.DataText>
            </div>

            <div>
              <S.Label>Título da Página</S.Label>
              <S.DataText>{data.pageTitle}</S.DataText>
            </div>

            <div>
              <S.Label>Descrição (TitleTag)</S.Label>
              <S.DataText>{data.descriptionTitleTag}</S.DataText>
            </div>

            {data.CBHProductName && (
              <div>
                <S.Label>Nome do Produto (Magazine Luiza)</S.Label>
                <S.DataText>{truncateString(data.CBHProductName, 40)}</S.DataText>
              </div>
            )}
          </S.Column>

          <S.Column>
            <div>
              <S.Label>SKU</S.Label>
              <S.DataText>{String(data.sku)}</S.DataText>
            </div>
          </S.Column>

          <S.Column>
            <div>
              <S.Label>Part Number</S.Label>
              <S.DataText>{data.partNumber}</S.DataText>
            </div>
          </S.Column>
        </S.RowContainer>

        <S.Column>
          <S.Label>Descrição (Ficha Técnica)</S.Label>

          <S.DescriptionContainer>
            <S.DescriptionDataText>
              <S.SanitizeHtmlTag
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data.descriptionTechnicalSheet),
                }}
              />
            </S.DescriptionDataText>
          </S.DescriptionContainer>
        </S.Column>
      </S.BGDataContainer>

      <S.BGLabel>Detalhes do Produto</S.BGLabel>

      <S.BGDetailsContainer>
        <S.BGFilterContainer>
          <S.Title>FILTROS</S.Title>

          {!hasFilters ? (
            <S.DataText>Nenhum filtro selecionado</S.DataText>
          ) : (
            <SelectedFilterIcons items={data.filters} />
          )}
        </S.BGFilterContainer>

        <S.BGIconsContainer>
          <S.Title>ÍCONES</S.Title>

          {!hasIcons ? (
            <S.DataText>Nenhum ícone selecionado</S.DataText>
          ) : (
            <SelectedFilterIcons items={data.icons} />
          )}
        </S.BGIconsContainer>
      </S.BGDetailsContainer>

      <S.BGLabel>Pack de Imagens</S.BGLabel>

      <S.BGImagePackContainer>
        {data?.showcase
          ?.sort((a, b) => Number(a.order) - Number(b.order))
          ?.map((item, index, arr) => {
            if (arr?.length === 1) {
              return (
                <S.ImageContainer key={index}>
                  <img src={item.filename_url} alt={item.name} width={100} height={100} />
                  <S.IMGLabel>Vitrine</S.IMGLabel>
                </S.ImageContainer>
              )
            }

            const isLast = index === arr.length - 1

            return (
              <S.Row key={index}>
                <S.ImageContainer>
                  <img src={item.filename_url} alt={item.name} width={100} height={100} />
                  <S.IMGLabel>{item.main === true ? 'Vitrine' : `Imagem ${index + 1}`}</S.IMGLabel>
                </S.ImageContainer>

                {!isLast && <Divider type="vertical" style={{ height: '100%' }} />}
              </S.Row>
            )
          })}
      </S.BGImagePackContainer>
    </S.Container>
  )
}
